

import { defineComponent, ref, onMounted, computed, onUpdated, HtmlHTMLAttributes } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddNewsModal from "@/components/modals/forms/AddNewsModal.vue";
//import EditNewsModal from "@/components/modals/forms/EditNewsModal.vue";
import AddStandardCategoriesModal from "@/components/modals/forms/AddStandardCategoriesModal.vue";
import AddCategoryAttribute from "@/components/modals/forms/AddCategoryAttribute.vue";
import AddAttributeRangeModal from "@/components/modals/forms/AddAttributeRangeModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
//import {ActionsRe} from "@/store/enums/StoreResearchEnums";

import { Modal } from "bootstrap";
//import UploadCategoryImage from "@/components/modals/forms/UploadCategoryImage.vue";


export default defineComponent({
  name: "customers-listing",
  components: {
    ExportCustomerModal,
    AddNewsModal, 
    //EditNewsModal,
    AddStandardCategoriesModal,
    AddAttributeRangeModal,
    AddCategoryAttribute,
    //UploadCategoryImage,
  },
  
  setup() {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);    
    const loadingData = ref<boolean>(true);
    const searchTearm = ref('');
    interface News {
      news_id: number,      
      user: {
        avatar: string;
        news_image_name: string,
      },      
      active: {
        label: string;
        color: string;
      },      
      news_title: string,
      news_description: string,
      category_name: string,
      //parent_category_name: string,
      news_type: number,
      minutes: string
    }

    var paginationData = ref({})
    
    paginationData.value = {      
        start : 0,
        end : 0,
        total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

    const refreshData =() => {      
      paginationData.value['activePage']  = 1;      
      getNewsList(searchTearm.value)
    }

    const changePageChange = (page) => {      
      paginationData.value['activePage']  = page;      
      getNewsList(searchTearm.value)
    }
    
    var tableData = ref<Array<News>>([]);
  
     const getNewsList = async (data) => {
      
      loadingData.value = true;

      try {

        var values = {"news_id": 0, "search_term": data, "page"  : parseInt(paginationData.value['activePage']), "records_per_page" : parseInt(paginationData.value['perPage']) }
        await store.dispatch(Actions.CUST_NEWS_LIST, values).then(({ data }) => {

          console.log(data);
          
          tableData.value = ([]);

          // set pagination
          paginationData.value['total'] = data.total_records;        
          paginationData.value['start'] = data.records_from
          paginationData.value['end'] = data.records_upto
          paginationData.value['activePage'] = data.page;
          paginationData.value['totPage']  = data.total_pages
          paginationData.value['pageRange'] = []
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }
          //endpagination
          
          var resultsM = ref<Array<News>>([])
          var status_label = ""
          var status_color = ""
          var active_label = ""
          var active_color = ""        

          for (let j = 0; j < data.result_list.length; j++) {

            if (data.result_list[j]['active']){
                active_label = "Yes";
                active_color = "success";                  
            }else{
                active_label = "No";
                active_color = "danger";
            }
            
            resultsM.value = Array({
              news_id : data.result_list[j]['news_id'],
              user : {
                news_image_name : data.result_list[j]['news_image_name'],
                avatar : data.result_list[j]['news_image_path']
              },
              
              status: {
                label: status_label,
                color: status_color
              },
              active: {
                label: active_label,
                color: active_color
              },
              news_title: data.result_list[j]['news_title'],
              news_description: data.result_list[j]['news_description'],
              category_name: data.result_list[j]['category_name'],
              //parent_category_name: data.result_list[j]['parent_category_name']
              news_type: data.result_list[j]['news_type'],
              minutes: data.result_list[j]['minutes_to_read']

            })

            tableData.value.splice(j, resultsM.value.length, ...resultsM.value);            
          }

          loadingData.value = false;

        }).catch(({ response }) => {

          tableData.value = [];
          loadingData.value = false;

       });

      } catch (e) {
        console.log(e);
      }
    };

      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });


      // var tableData = ref<Array<WICompanies>>([]);
      const initCustomers = ref<Array<News>>([]);                      

      onMounted( async () => {
        await getNewsList(searchTearm.value)
        setCurrentPageBreadcrumbs("News", []);
        initCustomers.value.splice(0, tableData.value.length, ...tableData.value);        
        console.log(tableData.value.length);
      });  

      const deleteFewCustomers = () => {
        checkedCompany.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedCompany.value.length = 0;
      };

      const deleteCustomer = (id) => {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].news_id === id) {
            tableData.value.splice(i, 1);
          }
        }
      };

      const search = ref<string>("");
       const searchItems = () => {
          searchTearm.value = search.value;
          refreshData();
          getNewsList(searchTearm.value);
          console.log(search.value);
          
      };

      const searchingFunc = (obj, value): boolean => {        
        for (let key in obj) {          
          if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {            
            if (obj[key].indexOf(value) != -1) {
              
              return true;
            }
          }
        }
        return false;
      };          
       
    return {
      tableData,      
      deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      deleteFewCustomers,
      count,      
      getNewsList,       
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      //image_modal,
      // standard_modal,
      // attr_range_modal,
      // attr_modal
    };  

  }  

});




